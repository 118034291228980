import { i18n } from '@/i18n'
import moment from 'moment'

function getCalendarPresets (min = null, max = null) {
  if (!min) {
    min = moment().format('YYYY-MM-DD')
  }
  if (!max) {
    max = moment().format('YYYY-MM-DD')
  }

  const presets = []

  const startWeek = moment(max).startOf('isoWeek')
  presets.push({
    name: i18n.t('app.currentWeek'),
    start: startWeek.isBefore(min) ? min : startWeek.format('YYYY-MM-DD'),
    end: max,
    disabled: false
  })

  const startLastWeek = moment(max).subtract(1, 'weeks').startOf('isoWeek')
  const endLastWeek = moment(max).subtract(1, 'weeks').endOf('isoWeek')
  presets.push({
    name: i18n.t('app.lastWeek'),
    start: startLastWeek.isBefore(min) ? min : startLastWeek.format('YYYY-MM-DD'),
    end: endLastWeek.isBefore(min) ? min : endLastWeek.format('YYYY-MM-DD'),
    disabled: false
  })

  const startMonth = moment(max).startOf('month')
  const endMonth = moment(max).endOf('month')
  presets.push({
    name: i18n.t('app.currentMonth'),
    start: startMonth.isBefore(min) ? min : startMonth.format('YYYY-MM-DD'),
    end: adjustDates(endMonth, min, max),
    disabled: false
  })

  const startYear = moment(max).startOf('year')
  const endYear = moment(max).endOf('year')
  presets.push({
    name: i18n.t('app.currentYear'),
    start: startYear.isBefore(min) ? min : startYear.format('YYYY-MM-DD'),
    end: adjustDates(endYear, min, max),
    disabled: false
  })

  const currentYear = moment(max).format('YYYY')
  const startQ1 = startYear
  const endQ1 = moment(currentYear + '-03-31')
  presets.push({
    name: i18n.t('app.q1'),
    start: adjustDates(startQ1, min, max),
    end: adjustDates(endQ1, min, max),
    disabled: isOutRange(startQ1, endQ1, min, max),
    format: 'compact'

  })

  const startQ2 = moment(currentYear + '-04-01')
  const endQ2 = moment(currentYear + '-06-30')
  presets.push({
    name: i18n.t('app.q2'),
    start: adjustDates(startQ2, min, max),
    end: adjustDates(endQ2, min, max),
    disabled: isOutRange(startQ2, endQ2, min, max),
    format: 'compact'
  })

  const startQ3 = moment(currentYear + '-07-01')
  const endQ3 = moment(currentYear + '-09-30')
  presets.push({
    name: i18n.t('app.q3'),
    start: adjustDates(startQ3, min, max),
    end: adjustDates(endQ3, min, max),
    disabled: isOutRange(startQ3, endQ3, min, max),
    format: 'compact'
  })

  const startQ4 = moment(currentYear + '-10-01')
  const endQ4 = endYear
  presets.push({
    name: i18n.t('app.q4'),
    start: adjustDates(startQ4, min, max),
    end: adjustDates(endQ4, min, max),
    disabled: isOutRange(startQ4, endQ4, min, max),
    format: 'compact'
  })

  return presets
}

function adjustDates (date, min, max) {
  if (date.isBefore(min)) {
    return min
  }
  if (date.isAfter(max)) {
    return max
  }
  return date.format('YYYY-MM-DD')
}

function isOutRange (start, end, min, max) {
  if (start.isBefore(min) && end.isBefore(min)) {
    return true
  }
  if (start.isAfter(max) && end.isAfter(max)) {
    return true
  }

  return false
}

export { getCalendarPresets }
