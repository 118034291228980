<template>
    <div
        :class="[isOpen ? 'translate-y-0 shadow-fullview':'translate-y-full']"
        class="fixed bg-white w-full h-full top-0 left-0 transition transform translate-y-0 z-50 duration-300">
        <slot />
    </div>
</template>

<script>
export default {
  name: 'full-modal-layout',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
