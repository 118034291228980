<template>
  <div class="flex flex-1">
    <div class="w-full h-full flex flex-col p-4">
      <template v-for="chart in localData">
        <div :key="chart.ref" class="flex flex-col w-full h-full">
          <h3 v-if="hasSite(chart.site)" class="font-bold text-2xl leading-tight py-2 text-center">{{ chart.site }}</h3>
          <div class="h-full relative">
            <highcharts class="absolute top-0 left-0 w-full h-full" :ref="chart.ref" :options="chart.options"></highcharts>
          </div>
        </div>
      </template>
    </div>
    <div v-if="!disableLegend" class="w-65 flex-shrink-0 overflow-y-auto bg-secondary-50">
      <div class="space-y-2 p-2 pb-4">
        <legend-label
          v-for="(category, index) in categories"
          :key="index"
          :category="category"
          :color="colors[index]"
          @toggle="toggleSeries"
          @highlight="highlightSeries"
          @mouseover="onMouseover"
          @mouseleave="onMouseleave"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LegendLabel from '@/components/LegendLabel.vue'
import { deepClone } from '@/utils/general'

function sync (vm, event, type) {
  for (const ref of vm.references) {
    const chart = vm.$refs[ref][0].chart

    if (chart === this.chart) return
    const points = []
    chart.series.forEach((series) => {
      series.data.forEach((point) => {
        if (point.x === this.x) {
          if (type === 'over') {
            points.push(point)
            point.setState('hover')
            chart.xAxis[0].drawCrosshair(event, point)
          } else {
            point.setState()
            chart.tooltip.hide()
            chart.xAxis[0].hideCrosshair()
          }
        }
      })
    })
    if (points.length) {
      chart.tooltip.refresh(points)
    }
  }
}

export default {
  name: 'AppCompareHighcharts',
  components: {
    LegendLabel
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    disableLegend: {
      type: Boolean,
      default: false
    },
    enableEvents: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      localData: deepClone(this.data),
      legendHover: null,
      legendDisable: [],
      checkedLegend: []
    }
  },
  computed: {
    references: function () {
      return this.localData.map(item => item.ref)
    }
  },
  methods: {
    hasSite: function (val) {
      return val !== ''
    },
    toggleSeries: function (name) {
      if (this.legendDisable.includes(name)) {
        this.legendDisable = this.legendDisable.filter(item => item !== name)
      } else {
        this.legendDisable.push(name)
      }

      for (const ref of this.references) {
        const index = this.getChartIndexByName(name, ref)
        if (index >= 0) {
          const chart = this.$refs[ref][0].chart
          if (chart) {
            const series = chart.series
            if (series[index].visible) {
              series[index].hide()
            } else {
              series[index].show()
            }
          }
        }
      }
    },
    highlightSeries: function (value) {
      if (this.checkedLegend.includes(value)) {
        this.checkedLegend = this.checkedLegend.filter(item => item !== value)
      } else {
        this.checkedLegend.push(value)
      }
    },
    onMouseover: function (name) {
      this.legendHover = name
    },
    onMouseleave: function () {
      this.legendHover = null
    },
    getChartIndexByName: function (brandName, serieRef) {
      let index = -1
      const object = this.localData.find(item => item.ref === serieRef)

      if (object) {
        index = object.series.findIndex(serie => serie.name === brandName)
      }

      return index
    },
    addInactiveSeriesAll: function () {
      this.setStateSeriesAll('inactive')
    },
    removeInactiveSeriesAll: function () {
      this.setStateSeriesAll()
    },
    removeInactiveSerie: function (name) {
      this.setStateOfSerie(name, 'hover')
    },
    setStateSeriesAll: function (state = '') {
      for (const reference of this.references) {
        const chartEl = this.$refs[reference][0]
        if (chartEl && chartEl.chart) {
          const series = chartEl.chart.series
          if (series && series.length) {
            for (const serie of series) {
              serie.setState(state)
            }
          }
        }
      }
    },
    setStateOfSerie: function (name, state = '') {
      for (const ref of this.references) {
        const index = this.getChartIndexByName(name, ref)
        if (index >= 0) {
          const chartEl = this.$refs[ref][0]
          if (chartEl && chartEl.chart) {
            const chart = chartEl.chart
            const series = chart.series
            if (series) {
              if (index < series.length) {
                series[index].setState(state)
              }
            }
          }
        }
      }
    },
    setCheckedLegend: function () {
      if (this.checkedLegend.length) {
        this.addInactiveSeriesAll()
        for (const value of this.checkedLegend) {
          this.removeInactiveSerie(value)
        }
      } else {
        this.removeInactiveSeriesAll()
      }
    },
    serieMouseOut: function () {
      setTimeout(() => {
        this.legendHover = null
      }, 100)
    },
    serieMouseOver: function (event) {
      const name = event.target.name
      setTimeout(() => {
        this.legendHover = name
      }, 100)
    },
    setSeriesEvents: function (data) {
      if (this.enableEvents) {
        const vm = this
        for (const chart of data) {
          chart.options.plotOptions.series.events = {
            mouseOut: this.serieMouseOut.bind(this),
            mouseOver: this.serieMouseOver.bind(this)
          }
          chart.options.plotOptions.series.point.events = {
            mouseOver: function (event) {
              sync.call(this, vm, event, 'over')
            },
            mouseOut: function (event) {
              sync.call(this, vm, event, 'out')
            }
          }
        }
      }

      return data
    }
  },
  created: function () {
    this.localData = this.setSeriesEvents(this.localData)
  },
  watch: {
    legendHover: function (value) {
      if (value !== null) {
        this.addInactiveSeriesAll()
        this.removeInactiveSerie(value)

        for (const item of this.checkedLegend) {
          this.removeInactiveSerie(item)
        }
      } else {
        this.setCheckedLegend()

        for (const item of this.checkedLegend) {
          this.removeInactiveSerie(item)
        }
      }
    },
    checkedLegend: function () {
      this.setCheckedLegend()
    }
  }
}
</script>
